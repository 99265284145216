export const isMarketplaceProductItem = (item) => {
  return item?.item_type === 'marketplace_product'
}

export const isNonProductionItem = (item) => {
  return item?.item_type === 'non_production'
}

export const isDesignServicesDrawingItem = (item) => {
  return item?.item_type === 'design_services_drawing'
}

export const isDesignServicesTemplateItem = (item) => {
  return item?.item_type === 'design_services_template'
}

export const isDesignServiceItem = (item) => {
  return isDesignServicesDrawingItem(item) || isDesignServicesTemplateItem(item)
}

export const isDesignServicesOrder = (order) => {
  return order?.reference_number[0] === 'D'
}

export const isLinkedDesignServiceOrder = (order) => {
  // Returns true if this order is not the purchased order, but rather is generated from the original.
  return isDesignServicesOrder(order) && Number(order.total_subtotal) === 0
}

export const isDesignServicesDrawingOrder = (order) => {
  const ds_line_items = order?.line_items.filter(item => isDesignServiceItem(item))
  return isDesignServicesOrder(order) && isDesignServicesDrawingItem(ds_line_items[0])
}

export const isDesignServicesTemplateOrder = (order) => {
  const ds_line_items = order?.line_items.filter(item => isDesignServiceItem(item))
  return isDesignServicesOrder(order) && isDesignServicesTemplateItem(ds_line_items[0])
}