import { bendAllowance, bendData } from 'shared/util/bend_math'
import Vector from 'shared/util/vector'

export default {
  props: {
    // Selectable bend lines
    bendLines: {
      type: Array,
      default() { return [] }
    },

    bendSelection: {
      type: Boolean,
      default: false
    },

    showUnconfiguredBends: {
      type: Boolean,
      default: false
    },

    showExtents: {
      type: Boolean,
      default: false
    },

    showDieLines: {
      type: Boolean,
      default: false
    },

    showMinFlanges: {
      type: Boolean,
      default: false
    },

    highlighted: {
      type: Array,
      default() { return [] }
    },

    ignored: {
      type: Array,
      default() { return [] }
    },

    selected: {
      type: Array,
      default() { return [] }
    }
  },

  watch: {
    bendSelection(enabled) {
      if (enabled) {
        this.initBending()
      }
      // Disable bending if bendSelection is turned off?
      // Looks like removing the 'clickable' class would work,
      // after running `makeBendsClickable`
    },
    highlighted(bends) {
      const elements = this.$refs.svgContainer.querySelectorAll(".bends .bend")

      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.classList.toggle('highlight', bends.includes(el.id))
      }
    },

    selected(bends) {
      const elements = this.$refs.svgContainer.querySelectorAll(".bends .bend")

      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.classList.toggle('select', bends.includes(el.id))
      }
    },

    ignored() {
      this.hideIgnoredBends()
    },

    bendConfig() {
      this.drawDieLines()
      this.drawBendExtents()
      this.drawMinFlanges()
      this.hideUnconfiguredBends()
    },
    showDieLines() {
      this.drawDieLines()
    },

    showMinFlanges() {
      this.drawMinFlanges()
    }
  },

  computed: {
    bendConfig() {
      return this.item.bends || []
    }
  },

  methods: {
    addClass(el, classString) {
      const className = el.getAttribute('class')
      const hasClass = new RegExp(classString).test(className)
      if (!hasClass) el.setAttribute('class', `${className} ${classString}`)
      return el
    },

    bendHandleClick(event) {
      const id = event.target.getAttribute('data-id')
      this.$emit('update:selected', id ? [id] : [])
    },

    hideIgnoredBends() {
      const elements = this.$refs.svgContainer.querySelectorAll(".bends .bend")

      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        el.classList.toggle('hidden', this.ignored.includes(el.id))
      }
    },

    hideUnconfiguredBends() {
      if (this.$refs.svgContainer && !this.showUnconfiguredBends) {
        const elements = this.$refs.svgContainer.querySelectorAll(".bends .bend")
        const configuredIds = this.bendConfig.map(b => b.id)

        for (let i = 0; i < elements.length; i++) {
          const el = elements[i]
          if (el.id) el.classList.toggle('hidden', !configuredIds.includes(el.id))
        }
      }
    },

    initBending() {
      if (this.bendSelection) {
        this.makeBendsClickable()
        this.hideIgnoredBends()
      }

      this.drawBendExtents()
      this.drawDieLines()
      this.drawMinFlanges()

      this.hideUnconfiguredBends()
    },

    makeBendsClickable() {
      const { svgContainer } = this.$refs

      if (svgContainer) {
        const bends = Array.from(svgContainer.getElementsByClassName('bend'))

        for (let i = 0; i < bends.length; i++) {
          const bend = bends[i]
          // Clone the bend node so we can make a larger, invisible, clickable area
          const clone = bend.cloneNode()

          // Encode the bend id in the selectable bend line
          clone.setAttribute('data-id', bend.id)

          // Insert the new invisible, selectable, bend line
          bend.parentNode.insertBefore(clone, bend)

          // Clear the id so it doesn't collide with the original
          clone.id = ''

          // Add the clickable class
          this.addClass(clone, 'clickable')
        }
      }
    },

    corners($line, dist) {
      const x1 = parseFloat($line.getAttribute('x1'))
      const y1 = parseFloat($line.getAttribute('y1'))
      const x2 = parseFloat($line.getAttribute('x2'))
      const y2 = parseFloat($line.getAttribute('y2'))

      // Determine start and end vectors
      const start = new Vector(x1, y1)
      const end = new Vector(x2, y2)

      // Calculate directional vectors with length (0.5 bend allowance)
      const direction = end.subtract(start).normalize()
      const perpendicular = new Vector(direction.y, -1 * direction.x).multiply(dist)

      // Calculate points for each of the 4 corners
      return [
        start.add(perpendicular),
        end.add(perpendicular),
        start.subtract(perpendicular),
        end.subtract(perpendicular),
      ]
    },

    drawDieLines() {
      const $svgContainer = this.$refs.svgContainer
      if (!$svgContainer) {
        return
      }
      if (this.showDieLines && !!this.item.material?.id) {
        const $old = $svgContainer.querySelector("#dielines")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.setAttribute('transform', 'matrix(1,0,0,-1,0,0)')
        g.id = "dielines"

        const { material, drawing } = this.item
        const { units } = drawing

        for (let i = 0; i < this.bendConfig.length; i++) {
          const { id } = this.bendConfig[i]
          const $line = $svgContainer.querySelector(`#${id}`)
          if ($line) {
            const { bottomDie } = bendData(material, units)

            const corners = this.corners($line, bottomDie / 2)

            // Determine die line points
            const ext1 = { x1: corners[0].x, y1: corners[0].y, x2: corners[1].x, y2: corners[1].y }
            const ext2 = { x1: corners[2].x, y1: corners[2].y, x2: corners[3].x, y2: corners[3].y }

            const path = [
              `M${ext1.x1},${ext1.y1}`,
              `L${ext1.x2},${ext1.y2}`,
              `L${ext2.x2},${ext2.y2}`,
              `L${ext2.x1},${ext2.y1}`,
              `L${ext1.x1},${ext1.y1}`
            ]

            // Draw die lines
            g.innerHTML += `<path d="${path.join(' ')}" />`

            window.$svgContainer = $svgContainer
            const $bends = $svgContainer.querySelector('.bends')
            $svgContainer.querySelector('svg').insertBefore(g, $bends)
          }
        }
      } else {
        const $old = $svgContainer.querySelector("#dielines")
        if ($old) $old.remove()
      }
    },

    drawMinFlanges() {
      const $svgContainer = this.$refs.svgContainer
      if (!$svgContainer) {
        return
      }

      const $old = $svgContainer.querySelector("#min-flanges")
      if ($old) $old.remove()

      let drawingMinFlanges = false

      if (this.showMinFlanges && !!this.item.material?.id && this.bendLines.length) {
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.setAttribute('transform', 'matrix(1,0,0,-1,0,0)')
        g.id = "min-flanges"

        const { material, drawing } = this.item
        const { units } = drawing

        for (let i = 0; i < this.bendLines.length; i++) {
          const { id } = this.bendLines[i]
          const $line = $svgContainer.querySelector(`#${id}`)
          if ($line) {
            const { minFlangeLength } = bendData(material, units)

            const corners = this.corners($line, (minFlangeLength * 2) / 2)

            // Determine flange points
            const ext1 = { x1: corners[0].x, y1: corners[0].y, x2: corners[1].x, y2: corners[1].y }
            const ext2 = { x1: corners[2].x, y1: corners[2].y, x2: corners[3].x, y2: corners[3].y }

            const path = [
              `M${ext1.x1},${ext1.y1}`,
              `L${ext1.x2},${ext1.y2}`,
              `L${ext2.x2},${ext2.y2}`,
              `L${ext2.x1},${ext2.y1}`,
              `L${ext1.x1},${ext1.y1}`
            ]

            // Draw bend flanges
            g.innerHTML += `<path d="${path.join(' ')}" />`

            window.$svgContainer = $svgContainer
            const $bends = $svgContainer.querySelector('.bends')
            $svgContainer.querySelector('svg').insertBefore(g, $bends)
          }
        }
        drawingMinFlanges = true
      }
      this.$emit('minFlangesVisible', drawingMinFlanges)
    },

    drawBendExtents() {
      if (this.showExtents && !!this.item.material?.id) {
        const $svgContainer = this.$refs.svgContainer
        const $old = $svgContainer.querySelector("#extents")
        if ($old) $old.remove()
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
        g.setAttribute('transform', 'matrix(1,0,0,-1,0,0)')
        g.id = "extents"

        const { material, drawing } = this.item
        const { units } = drawing

        for (let i = 0; i < this.bendConfig.length; i++) {
          const { angle, id } = this.bendConfig[i]
          const allowance = bendAllowance(material, units, angle)
          const $line = $svgContainer.querySelector(`#${id}`)
          if ($line) {
            const corners = this.corners($line, allowance / 2)

            // Determine extent points
            const ext1 = { x1: corners[0].x, y1: corners[0].y, x2: corners[1].x, y2: corners[1].y }
            const ext2 = { x1: corners[2].x, y1: corners[2].y, x2: corners[3].x, y2: corners[3].y }

            const path = [
              `M${ext1.x1},${ext1.y1}`,
              `L${ext1.x2},${ext1.y2}`,
              `L${ext2.x2},${ext2.y2}`,
              `L${ext2.x1},${ext2.y1}`,
              `L${ext1.x1},${ext1.y1}`
            ]

            // Draw bend extents
            g.innerHTML += `<path d="${path.join(' ')}" />`

            window.$svgContainer = $svgContainer
            const $bends = $svgContainer.querySelector('.bends')
            $svgContainer.querySelector('svg').insertBefore(g, $bends)
          }
        }
      }
    },
    bendHandleMouseover(event) {
      if (!this.bendSelection) return
      const { target } = event
      if (!target.classList.contains('bend')) {
        this.$emit('update:highlighted', [])
        return
      }

      const id = target.getAttribute('data-id')
      this.$emit('update:highlighted', [id])
    }
  },
}
