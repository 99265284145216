import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { initApp, routeCheck } from './utilities'
import { EventBus } from 'shared/services/event_bus.js'

Vue.use(VueRouter)

import {
  Cart,
  Order,
  Orders,
  Quote,
  Referrals,
  SavedCarts,
  Settings
} from '../components/root'

import CustomerProduct from 'customer/components/customer_products/CustomerProduct'
import CustomerProducts from 'customer/components/customer_products/CustomerProducts'

const defaultRoute = 'quote'

const routes = [
  { path: '/', redirect: { name: defaultRoute } },
  { path: '/settings/:routeTab?', name: 'settings', component: Settings, props: true },
  { path: '/cart', name: 'cart', component: Cart },
  { path: '/carts', name: 'carts', component: SavedCarts },
  { path: '/orders', name: 'orders', component: Orders },
  { path: '/orders/:id', name: 'order', component: Order },
  { path: '/products', name: 'products', component: CustomerProducts },
  {
    path: '/products/:productId(\\d+)',
    name: 'product',
    component: CustomerProduct,
    props: route => ({ productId: Number(route.params.productId) })
  },
  { path: '/quote', name: 'quote', component: Quote },
  { path: '/referrals', name: 'referrals', component: Referrals },
  { path: '*', redirect: { name: defaultRoute } },
]

const router = new VueRouter({
  routes,
  base: '/customer/',
})

router.beforeEach((to, from, next) => {
  // Fetch that user
  if (!store.state.app.initialized) {
    initApp(store, to).then(() => {
      next(routeCheck(store, to))
    })
  }
  else {
    next(routeCheck(store, to))
  }
})

router.afterEach((to, from) => {
  EventBus.$emit('route_changed', { to, from })
})

export default router
