// This should match TERMS in organization.rb
export const TERMS = {
  NO_TERMS: 'No Terms',
  SUSPENDED: 'Suspended',
  NET_30: 'NET 30',
  NET_45: 'NET 45',
  NET_60: 'NET 60',
  NET_90: 'NET 90'
}

export const ACTIVE_TERMS = [TERMS.NET_30, TERMS.NET_45, TERMS.NET_60, TERMS.NET_90]