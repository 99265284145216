import { scsFetch } from 'shared/scs/scsFetch'

export const fetch = async (orderID, data = {}) => {
  const resp = await scsFetch(`/orders/${orderID}/shipped_packages`, 'GET', data)
  return resp
}

export const create = async (shippedPackage) => {
  const resp = await scsFetch(`/orders/${shippedPackage.order_id}/shipped_packages`, "POST", shippedPackage)
  return resp.json()
}

export const get = async (shippedPackage) => {
  const resp = await scsFetch(`/orders/${shippedPackage.order_id}/shipped_packages/${shippedPackage.id}`, "GET")
  return resp.json()
}

export const update = async (shippedPackage) => {
  const resp = await scsFetch(`/orders/${shippedPackage.order_id}/shipped_packages/${shippedPackage.id}`, "PUT", shippedPackage)
  return resp.json()
}

export const destroy = async (shippedPackage) => {
  const resp = await scsFetch(`/orders/${shippedPackage.order_id}/shipped_packages/${shippedPackage.id}`, "DELETE")
  return resp.json()
}
