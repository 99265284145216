export default {
  data() {
    return {
      basePath: null,
      overlay: null,
      pattern: null,
      gradient: null,
      id: null,
    }
  },

  props: {
    showFinish: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    finish() {
      const { operations, powder_config, anodizing_config, plating_config } = this.item

      if (operations && operations.length > 0) {
        if (operations.includes('powder') && powder_config) {
          return this.powderColors.find(c => c.value == powder_config)
        }

        if (operations.includes('anodizing') && anodizing_config) {
          return this.anodizingColors.find(c => c.value == anodizing_config)
        }

        if (operations.includes('plating') && plating_config) {
          return this.platingColors.find(c => c.value == plating_config)
        }
      }

      return {}
    },

    anodizingColors() {
      return this.$store.getters['finish_options/allForType']('anodizing')
    },

    platingColors() {
      return this.$store.getters['finish_options/allForType']('plating')
    },

    powderColors() {
      return this.$store.getters['finish_options/allForType']('powder')
    },

    swatch() {
      let type = ''
      const { swatch } = this.finish

      if (swatch && swatch.url) type = 'pattern'
      if (swatch && swatch.color) type = 'color'
      if (swatch && swatch.gradient) type = 'gradient'
      if (swatch && swatch.url && swatch.gradient) type = 'pattern-gradient'

      return {
        ...swatch,
        type
      }
    },

    patternID() {
      return `${this.id}_${this.finish.value}_pattern`
    },

    gradientID() {
      return `${this.id}_${this.finish.value}_gradient`
    },

    px() {
      const $svg = this.$refs.svgContainer.querySelector('svg')
      if ($svg) {
        const { width, height } = $svg.getBoundingClientRect()
        const [vbx, vby, vbwidth, vbheight] = $svg.getAttribute('viewBox').split(' ')
        return width > 0 ? vbwidth / width : 0
      }

      return 1
    },
  },

  watch: {
    finish(finish) {
      if (finish && this.$refs.svgContainer && this.basePath) {
        const $svg = this.$refs.svgContainer.querySelector('svg')

        if ($svg) {
          this.setSwatch($svg)
          this.setFinish()
        }
      }
    }
  },

  methods: {
    initFinishing() {
      if (this.px > 0) {
        this.id = `${this.item.id || ''}_${Math.floor(Math.random() * 100)}`

        // Store base path
        const $svg = this.$refs.svgContainer.querySelector('svg')
        this.basePath = $svg.querySelector('.base path')

        // Create the swatch/pattern
        this.setSwatch($svg)

        // Set the finish pattern url swatch
        this.setFinish()
      }
    },

    setSwatch($svg) {
      if (this.pattern) this.pattern.remove()
      if (this.gradient) this.gradient.remove()

      $svg.classList.toggle('dark', !!this.swatch.dark)

      switch (this.swatch.type) {
        case 'pattern':
          this.createPattern($svg)
          break
        case 'gradient':
          this.createLinearGradient($svg)
          break
        case 'pattern-gradient':
          this.createPattern($svg)
          this.createLinearGradient($svg)
          break
        case 'color':
        default:
      }
    },

    createPattern($svg) {
      const $defs = $svg.querySelector('defs')

      // Create finish pattern
      this.pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern")
      this.pattern.id = this.patternID
      this.pattern.class = this.patternID
      this.pattern.setAttribute('width', this.swatch.size * this.px)
      this.pattern.setAttribute('height', this.swatch.size * this.px)
      this.pattern.setAttribute('patternUnits', 'userSpaceOnUse')
      this.pattern.innerHTML = `
        <image x="0" y="0"
          href="${this.finish.swatch.url}"
          width="${this.swatch.size * this.px}"
          height="${this.swatch.size * this.px}"
        />
      `

      // Add finish pattern to defs
      $defs.appendChild(this.pattern)
    },

    createLinearGradient($svg) {
      const $defs = $svg.querySelector('defs')

      this.gradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient")
      this.gradient.setAttribute('gradientTransform', 'rotate(45)')
      this.gradient.id = this.gradientID
      this.gradient.innerHTML = this.swatch.gradient.reduce((content, grad) => {
        content += `<stop offset="${grad.percent}%" stop-color="${grad.color}" />`
        return content
      }, '')

      $defs.appendChild(this.gradient)
    },

    setFinish() {
      if (this.overlay) this.overlay.remove()

      switch (this.swatch.type) {
        case 'pattern':
          this.basePath.setAttribute('style', `fill: url(#${this.patternID}) !important`)
          break
        case 'color':
          this.basePath.setAttribute('style', `fill: ${this.swatch.color} !important`)
          break
        case 'gradient':
          this.basePath.setAttribute('style', `fill: url(#${this.gradientID}) !important`)
          break
        case 'pattern-gradient':
          this.basePath.setAttribute('style', `fill: url(#${this.patternID}) !important`)

          // Create an overlay to display the gradient
          this.overlay = this.basePath.cloneNode()
          this.overlay.setAttribute('style', `fill: url(#${this.gradientID}) !important`)
          this.basePath.parentNode.appendChild(this.overlay)
          break
        default:
          this.basePath.setAttribute('style', '')
      }
    }
  }
}
